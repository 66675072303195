<script lang="ts" setup>
const emit = defineEmits(["click"]);
</script>

<template>
	<div class="banner-mob-bar" @click="emit('click')">
		<slot />
	</div>
</template>

<style lang="scss" scoped>
.banner-mob-bar {
	width: 100%;
	height: 50px;
	padding: 8px 16px 18px;
	display: flex;
	align-items: center;
	background: var(--secondary-500);
	border-radius: 12px 12px 0 0;
}
</style>
